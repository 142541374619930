export const USER_ASSIGNMENT_STATUS = [
  {
    id: 'editInProgress',
    label: 'In Bearbeitung',
  },
  {
    id: 'editFinished',
    label: 'Bearbeitung abgeschlossen',
  },
  {
    id: 'completed',
    label: 'Fertig',
  },
];

export const PROJECT_TAG_REFERENCES = [
  {
    id: 'battery',
    label: 'Batterie',
    tags: [
      {
        id: 'energy-storage-type',
        label: 'Energiespeichertyp',
      },
      {
        id: 'anode',
        label: 'Anode',
      },
      {
        id: 'cathode',
        label: 'Kathode',
      },
      {
        id: 'special-properties',
        label: 'besondere Eigenschaften',
      },
      {
        id: 'electrolyte',
        label: 'Elektrolyt',
      },
      {
        id: 'separator',
        label: 'Separator',
      },
      {
        id: 'cell-format',
        label: 'Zellformat',
      },
    ],
  },
  {
    id: 'research',
    label: 'Forschung',
    tags: [
      {
        id: 'field-of-application',
        label: 'Anwendungsgebiet',
      },
      {
        id: 'research-subject',
        label: 'Forschungsgegenstand',
      },
      {
        id: 'research-area',
        label: 'Forschungsgebiet',
      },
      {
        id: 'method',
        label: 'Methode',
      },
      {
        id: 'investigated-property',
        label: 'Eigenschaft',
      },
    ],
  },
];

export const ORGANIZATION_TAG_REFERENCES = [
  {
    id: 'sector',
    label: 'Branche',
  },
  {
    id: 'products',
    label: 'Produkte',
  },
  {
    id: 'services',
    label: 'Dienstleistungen',
  },
  {
    id: 'energy-storage-type',
    label: 'Energiespeichertyp',
  },
  {
    id: 'infrastructure',
    label: 'Infrastruktur',
  },
  /* {
    id: 'focus',
    label: 'Schwerpunkte',
  }, */
  /* {
      id: 'organisation',
      label: 'Organisation',
      tags: [

      ],
    }, */
];

export const INFRASTRUCTURE_TAG_REFERENCES = [
  {
    id: 'infrastructure',
    label: 'Infrastrukturtyp',
  },
];

export const INFRASTRUCTURE_CELL_TAG_REFERENCES = [
  {
    id: 'energy-storage-type',
    label: 'Energiespeichertyp',
  },
  {
    id: 'anode',
    label: 'Anode',
  },
  {
    id: 'cathode',
    label: 'Kathode',
  },
  {
    id: 'special-properties',
    label: 'besondere Eigenschaften',
  },
  {
    id: 'electrolyte',
    label: 'Elektrolyt',
  },
  {
    id: 'separator',
    label: 'Separator',
  },
  {
    id: 'cell-format',
    label: 'Zellformat',
  },
  {
    id: 'electrode-processing',
    label: 'Elektrodenprozessierung',
  },
];
